<template>
	<router-view v-if="mobile"></router-view>
	<div v-else class="g-wrapper flex-column">
		<div class="g-header flex-between-center">
			<img class="g-logo" src="@/assets/image/logo.png">
			
			<div class="flex">
				<div class="g-handle flex-center" @click="navigateTo('/message_list')" title="消息中心">
					<i class="tf tf-bell"></i>
					<ins v-if="messageTotal > 0" class="dot"></ins>
				</div>
				<div class="g-handle flex-center" @click="manualHandle" title="操作手册">
					<i class="tf tf-manual"></i>
				</div>
				<div class="g-user flex-center">
					<div class="icon flex-center"><img src="@/assets/image/user_icon.png"></div>
					<span class="name">{{userInfo.name}}【{{userInfo.user_realname}}】</span>
					<i class="tf tf-tri-down"></i>
					
					<div v-if="outside" class="user-handle">
						<div class="item" @click="logout">安全退出</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="flex-grow flex">
			<div class="g-nav flex-column">
				<div class="home flex-center-cross" :class="{'z-crt': alivePage == 'home'}" @click="navigateTo({name: 'home'})">
					<i class="icon tf tf-menu-home"></i>
					<span>首页工作台</span>
				</div>
				<div class="flex-grow">
					<div v-for="(group, i) in navList" :key="i" @click="aliveNavItem = i">
						<div class="group flex-between-center" :class="{'z-crt': aliveNavItem == i}">
							<p class="flex-center-cross">
								<i class="icon tf" :class="group.icon"></i>
								<span>{{group.title}}</span>
							</p>
							<i class="angle tf tf-angle-right"></i>
						</div>
						<ul :style="{'height': aliveNavItem == i ? `${group.children.length * 36}px` : 0}">
							<li v-for="(item, j) in group.children" :key="j" :class="{'z-crt': alivePage == item.name}" @click="navigateTo({name: item.name})">{{item.title}}</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="flex-grow flex-column">
				<div class="g-tab-list flex">
					<div class="indicator left flex-center" @click="tabMoveHandle(1)"><i class="tf tf-angle-left"></i></div>
					<div class="tab home flex-center" :class="{'z-crt': alivePage == 'home'}" @click="navigateTo({name: 'home'})">
						<i class="tf tf-home"></i>
					</div>
					<div ref="tabWrapper" class="list flex-grow">
						<ul ref="tabList" :style="{'left': `${tabListSeat}px`}">
							<li ref="tab" v-for="(item, i) in tabList" :key="i" class="tab" :class="{'z-crt': alivePage == item.name}" @click="navigateTo(item.path ? item.path : {name: item.name})" @contextmenu.prevent="showRightMenu(item, i)">
								<div class="flex-between-center">
									<span>{{item.title}}</span>
									<a class="tf tf-cross" @click.stop="closePage(item.name)"></a>
								</div>
							</li>
						</ul>
					</div>
					<div class="indicator right flex-center" @click="tabMoveHandle(-1)"><i class="tf tf-angle-right"></i></div>
					<div v-show="rightMenuTab" class="right-menu" :style="{'left': rightMenuPosition}">
						<div v-for="(item, i) in rightMenuList" :key="i" class="item" @click="rightMenuHandle(item.value)">{{item.name}}</div>
					</div>
				</div>
				<div class="g-pages flex-grow">
					<keep-alive :include="keepAlive">
						<router-view></router-view>
					</keep-alive>
				</div>
			</div>
		</div>
		
		<c-dialog ref="resetPsw" title="修改密码" width="600" @resolve="resetPassword" @close="$refs.resetPswForm.clear()">
			<c-form ref="resetPswForm">
				<c-form-item type="password" label="旧密码" name="old_pwd" required></c-form-item>
				<c-form-item type="password" label="新密码" name="user_pwd" required></c-form-item>
				<c-form-item type="password" label="确认密码" name="confirm_pwd" required></c-form-item>
			</c-form>
		</c-dialog>
	</div>
</template>

<script>
	import clipboard from '@/assets/script/lib/clipboard.js'
	import fun from '@/assets/script/function.js'
	import {mapState, mapGetters} from 'vuex'
	
	export default {
		data() {
			return {
				outside: false,
				
				tabList: [],
				tabListSeat: 0,
				
				rightMenuList: [{
					name: '关闭',
					value: 'close'
				}, {
					name: '关闭其他标签',
					value: 'closeOther'
				}, {
					name: '关闭右侧标签',
					value: 'closeRight'
				}, {
					name: '全部关闭',
					value: 'closeAll'
				}],
				rightMenuTab: false,
				rightMenuPosition: 0,
				
				aliveNavItem: -1,
				alivePage: '',
				
				historyList: []
			};
		},
		
		computed: {
			...mapState(['mobile', 'userInfo', 'messageTotal']),
			...mapGetters(['navList']),
			
			keepAlive() {
				return ['home'].concat(Array.from(this.tabList, item => {
					return item.name;
				}));
			},
			
			navLibrary() {
				return Array.from(this.navList, group => {
					let keys = [];
					group.children.forEach(item => {
						keys.push({
							name: item.name,
							title: item.title
						});
					});
					return keys;
				});
			}
		},
		
		watch: {
			$route(val) {
				this.historyList.push({
					path: val.fullPath,
					name: val.name
				});
				if (!this.mobile) {
					this.updateAlivePage();
				}
			}
		},
		
		mounted() {
			if (!this.mobile) {
				this.updateAlivePage();
				
				this._resizeHandler = fun.throttle(() => {
					this.tabMoveHandle();
				}, 200, false)
				
				window.addEventListener('resize', this._resizeHandler);
				window.addEventListener('click', () => {
					this.rightMenuTab = false;
				});
				
				this.$store.dispatch('updateMessageTotal');
				
				dd.getConfig().then().catch(err => {
					this.outside = true;
				});
			}
		},
		
		beforeDestroy() {
			window.removeEventListener('resize', this._resizeHandler);
		},

		methods: {
			tabMoveHandle(dir) {
				this.$nextTick(() => {
					var wrapper = this.$refs.tabWrapper.offsetWidth,
						list = this.$refs.tabList.offsetWidth,
						left = Math.abs(this.tabListSeat),
						maxLeft = list - wrapper;
						
					if (dir) {
						if (maxLeft > 0) {
							this.tabListSeat += dir * parseInt(wrapper * 0.6);
							this.tabListSeat = this.tabListSeat > 0 ? 0 : this.tabListSeat < -maxLeft ? -maxLeft : this.tabListSeat;
						}
						return;
					}
						
					var $crt = this.$refs.tab && this.$refs.tab.find(item => {
						return item.getAttribute('class').includes('z-crt');
					});
					
					if ($crt && maxLeft > 0) {
						if ($crt.offsetLeft < left) {
							this.tabListSeat = -$crt.offsetLeft;
						} else if ($crt.offsetLeft > wrapper + left - $crt.offsetWidth) {
							this.tabListSeat = wrapper - $crt.offsetWidth - $crt.offsetLeft;
						} else {
							this.tabListSeat = this.tabListSeat < -maxLeft ? maxLeft < 0 ? 0 : -maxLeft : this.tabListSeat;
						}
					} else {
						this.tabListSeat = this.tabListSeat < -maxLeft ? maxLeft < 0 ? 0 : -maxLeft : this.tabListSeat;
					}
				});
			},
			
			updateAlivePage(route = this.$route) {
				let page, index = this.navLibrary.findIndex(group => {
					page = group.find(item => {
						return item.name == route.name;
					});
					return !!page;
				});
				this.aliveNavItem = route.name == 'home' ? -1 : (index > -1 ? index : this.aliveNavItem);
				this.alivePage = route.name;
				
				if (route.name != 'home') {
					var tabIndex = this.tabList.findIndex(item => item.name == route.name);
					page = page || {
						name: route.name,
						path: route.fullPath,
						title: route.meta.title
					}
					if (tabIndex == -1) {
						this.tabList.push(page);
					} else {
						this.tabList[tabIndex] = page;
					}
					
					this.tabMoveHandle();
				}
			},
			
			closePage(keys, back = true) {
				keys = typeof keys == 'string' ? [keys] : keys;
				for (let i = keys.length - 1; i >= 0; i--) {
					let key = keys[i];
					var index = this.tabList.findIndex(item => item.name == key)
					this.tabList.splice(index, 1);
				}
				if (back && keys.includes(this.$route.name)) {
					for (var i = this.historyList.length - 1; i >= 0; i--) {
						var history = this.historyList[i];
						if (this.tabList.find(item => item.name == history.name)) {
							this.historyList.splice(i + 1);
							this.navigateTo(history.path);
							break;
						}
					}
					
					if (i == -1) {
						this.navigateTo('/');
					}
				} else {
					this.tabMoveHandle();
				}
			},
			
			closeAlivePage() {
				this.closePage(this.alivePage, false);
			},
			
			showRightMenu(item, index) {
				this.rightMenuTab = item;
				this.rightMenuPosition = this.$refs.tab[index].offsetLeft + this.tabListSeat + 75 + 'px';
			},
			
			rightMenuHandle(type) {
				var keys;
				switch (type) {
				case 'close':
					keys = [this.rightMenuTab.name];
					break;
					
				case 'closeOther':
					keys = Array.from(this.tabList.filter(item => item != this.rightMenuTab), item => item.name);
					break;
					
				case 'closeRight':
					var index = this.tabList.findIndex(item => item == this.rightMenuTab);
					keys = Array.from(this.tabList.filter((item, i) => i > index), item => item.name);
					break;
					
				case 'closeAll':
					keys = Array.from(this.tabList, item => item.name);
				}
				
				keys.length && this.closePage(keys);
			},
			
			manualHandle() {
				window.open('https://api.szbhjg.cn/upload/业主端操作手册.pdf');
			},
			
			logout() {
				this.$confirm({
					text: '确定要退出登录吗？',
					resolve: () => {
						this.request({
							url: '/user/logout',
							loading: true,
							success: () => {
								fun.removeCookie('session_id');
								this.$store.commit('setUserInfo', {});
								this.navigateTo('/login');
							}
						});
					}
				});
			},
			
			resetPassword(stop) {
				stop();
				this.$refs.resetPswForm.submit({
					url: '/sys_user/update_pwd',
					rule: {
						old_pwd: '请填写旧密码',
						user_pwd: '请填写新密码',
						confirm_pwd: [{
							type: 'empty',
							word: '请在填写一次新密码'
						}, {
							type: 'function',
							fun: (value, data) => {
								return data.user_pwd == value
							},
							word: '两次输入的新密码不一致'
						}]
					},
					dataFormatter: data => {
						data.old_pwd = CryptoJs.MD5(data.old_pwd).toString();
						data.user_pwd = CryptoJs.MD5(data.user_pwd).toString();
						delete data.confirm_pwd;
						return data;
					},
					success: () => {
						this.$message({
							text: '密码已修改'
						});
						this.$refs.resetPsw.close();
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.g {
		&-wrapper {
			height: 100vh;
			overflow: hidden;
		}
		
		&-header {
			z-index: 100;
			position: relative;
			height: 50px;
			color: #fff;
			background: #0080FF;
			box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);
		}
		
		&-logo {
			height: 30px;
			margin-left: 14px;
		}
		
		&-handle {
			position: relative;
			width: 50px;
			height: 50px;
			font-size: 18px;
			cursor: pointer;
			transition: all .3s ease;
			
			&:hover {
				background: #3296FA;
			}
			
			.dot {
				position: absolute;
				right: 10px;
				top: 10px;
				width: 5px;
				height: 5px;
				border-radius: 100%;
				background: #f00;
			}
		}
		
		&-user {
			position: relative;
			height: 50px;
			padding: 0 12px;
			cursor: pointer;
			transition: all .3s ease;
			
			&:hover {
				background: #3296FA;
				
				.user-handle {
					display: block;
				}
			}
			
			.icon {
				width: 30px;
				height: 30px;
				border-radius: 100%;
				overflow: hidden;
				background: #fff;
			}
			
			.name {
				margin: 0 6px 0 10px;
				font-size: 14px;
			}
			
			> .tf {
				font-size: 12px;
			}
			
			.user-handle {
				display: none;
				z-index: 12;
				position: absolute;
				left: 0;
				right: 0;
				top: 100%;
				padding: 8px 0;
				background: #fff;
				box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
				
				.item {
					position: relative;
					padding: 0 10px 0 16px;
					line-height: 36px;
					font-size: 13px;
					color: #333;
					
					&:hover {
						font-weight: bold;
						color: #3296FA;
						background: #F5F5F5;
					}
				}
			}
		}
		
		&-nav {
			z-index: 100;
			position: relative;
			width: 190px;
			color: #1F1F1F;
			background: #fff;
			
			.home {
				height: 40px;
				border-bottom: 1px solid #eee;
				padding: 0 5px 5px;
				margin: 10px;
				font-size: 16px;
				color: #000;
				cursor: pointer;
				
				&:hover,
				&.z-crt {
					font-weight: bold;
					color: #0080FF;
					
					.icon {
						color: #0080FF;
					}
				}
				
				.icon {
					width: 28px;
					font-size: 16px;
					color: #778292;
				}
			}
			
			.group {
				height: 46px;
				padding: 0 15px;
				font-size: 15px;
				cursor: pointer;
				
				&:hover
				&.z-crt {
					font-weight: bold;
				}
				
				&.z-crt {
					.angle {
						transform: rotate(90deg);
					}
				}
				
				.icon {
					width: 28px;
					font-size: 18px;
					font-weight: normal;
					color: #333;
				}
				
				.angle {
					font-size: 12px;
					color: #999;
					transition: transform .3s ease;
				}
			}
			
			ul {
				height: 0;
				overflow: hidden;
				transition: height .3s ease;
				
				li {
					padding: 0 5px;
					margin: 0 10px;
					line-height: 36px;
					font-size: 14px;
					text-indent: 28px;
					color: #555;
					cursor: pointer;
					
					&:hover,
					&.z-crt {
						font-weight: bold;
						color: #3296FA;
						background: #F5F7FE;
					}
				}
			}
		}
		
		&-tab-list {
			z-index: 99;
			position: relative;
			height: 40px;
			border-top: 1px solid #eee;
			background: #fff;
			box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);
			
			.list {
				z-index: 10;
				position: relative;
				overflow: hidden;
				background: #fff;
			
				ul {
					float: left;
					position: relative;
					font-size: 0;
					white-space: nowrap;
					transition: left .2s ease;
					
					li {
						display: inline-block;
						min-width: 80px;
						
						div {
							height: 100%;
						}
					}
				}
			}
			
			.tab {
				&.home {
					box-sizing: border-box;
					min-width: auto;
					width: 45px;
					font-size: 18px;
				}
				
				position: relative;
				height: 40px;
				border-right: 1px solid #ddd;
				padding: 0 10px;
				cursor: pointer;
				
				&:hover {
					background: #FAFAFA;
				}
				
				&.z-crt {
					span {
						font-weight: bold;
					}
					
					&:before {
						width: 100%;
						background: #FF9F00;
					}
				}
				
				span {
					margin-right: 14px;
					font-size: 14px;
					white-space: nowrap;
					color: #444;
				}
				
				a {
					padding: 2px;
					border-radius: 100%;
					font-size: 12px;
					color: #aaa;
					
					&:hover {
						background: #EB0000;
						color: #fff;
					}
				}
				
				&:before {
					content: '';
					position: absolute;
					left: 50%;
					top: 0;
					width: 0;
					height: 2px;
					transform: translate(-50%,0);
					transition: width .15s ease;
				}
			}
			
			.indicator {
				z-index: 10;
				position: relative;
				width: 30px;
				cursor: pointer;
				
				&.left {
					border-right: 1px solid #ddd;
				}
				
				&.right {
					border-left: 1px solid #ddd;
					margin-left: -1px;
				}
				
				&:hover {
					font-weight: bold;
					background: #FAFAFA;
				}
			}
				
			.right-menu {
				box-sizing: border-box;
				z-index: 100;
				position: absolute;
				left: 76px;
				top: 40px;
				width: 160px;
				border: 1px solid #ddd;
				padding: 10px 0;
				font-size: 13px;
				color: #666;
				background: #fff;
				box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
				
				.item {
					padding: 0 20px;
					line-height: 36px;
					cursor: pointer;
					
					&:hover {
						font-weight: bold;
						color: #3296FA;
						background: #F5F5F5;
					}
				}
			}
		}
		
		&-pages {
			position: relative;
			overflow-x: hidden;
			overflow-y: auto;
		}
	}
	
	.m-iframe {
		width: 100%;
		border: none;
	}
</style>